import React, { useEffect } from 'react'
import { Div } from './styles/layout.style';
import { getStorageItem } from '../../utils/local-storage';
import { useAppContext } from '../../context';
import { Action } from '../../context/actions';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { capitalize } from 'utils/format-value';

type layoutProps = {
  children: React.ReactNode
}

const Layout: React.FC<layoutProps> = ({ children }) => {
  const { state: { user ,vendorBusiness}, dispatch } = useAppContext()

  useEffect(() => {
    const storage = getStorageItem('isAuth');

    if (user) {
      dispatch({
        type: Action.LOGIN,
        payload: storage
      });
    } else {

    }

  }, [])
  useDocumentTitle(capitalize(vendorBusiness?.storeId))
  return (
    <Div>
      {children}
    </Div>
  )
}

export default Layout