import { z } from "zod";


type OptionProps = {
  name: string;
  values: string[];
}

export const formIsValid = (payload: { [key: string]: any }) => {
  let isValid = false;
  let result = Object.keys(payload).filter((key) => payload[key] === '');
  if (result?.length) {
    isValid = false;
  } else {
    isValid = true;
  }
  return isValid;
};


export const SignupUserSchema = z
  .object({
    storeName: z.string().min(1, { message: "Store name is required" }),
    firstName: z.string().min(1, { message: "First name is required" }),
    lastName: z.string().min(1, { message: "Last name is required" }),
    email: z.string().min(1, { message: "Email is required" }).email({
      message: "Must be a valid email",
    }),
    phoneNumber: z.string({ required_error: "Phone number is required" }).min(11, { message: "Phone must be atleast 11 characters" }),
    password: z
      .string({ required_error: "Password is required" })
      .min(8, { message: "Password must be atleast 8 characters" }),
    confirmPassword: z
      .string({ required_error: "Confirm password is required" })
      .min(1, { message: "Confirm Password is required" }),

  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "Password and Confirm  Password does not match",
  });



export const SigninUserSchema = z
  .object({
    email: z.string().min(1, { message: "Email is required" }).email({
      message: "Must be a valid email",
    }),
    password:
      z.string({
        invalid_type_error: "Password is required",
      }).min(8, { message: "Password must be atleast 8 characters" }),
  });

export function removeStatePrefix(value: string): string {

  let inputString = value.toLowerCase();
  if (inputString.includes('state')) {
    const parts = inputString.split('state');
    const result = parts[0].trim();
    return result;
  } else {
    return inputString.trim();
  }
}

export function validEmail(email: string) {
  let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return email.match(mailformat)
}

export function hasValidValues(array: OptionProps[]): boolean {
  return !array.some(obj => {
    return Object.entries(obj).some(([key, value]) => {
      if (key === 'name' && value === '') {
        return true;
      }
      if (key === 'values' && (value.includes(''))) {
        return true;
      }
      return false;
    });
  });
}

export const AddCollectionSchema = z
  .object({
    name: z.string().min(1, { message: "Collection name is required" }),
    description: z.string().min(1, { message: "Collection description is required" }),
  });

const hostUrl = window.location.host

export function getCustomUrl() {
  let domain = hostUrl;
  // domain = 'epaytag.com'
  // domain = 'mshinestore.test.whatadeal.shop'
  const regex = new RegExp(`(.*)\\.${process.env.REACT_APP_STORE_BASE_URL}\\.shop`);

  if (domain) {
    const match = domain.match(regex);
    if (match) {
      return { domain: match[1], isCustomDomain: false };
    }

    const cleanDomain = domain.replace(/^(https?:\/\/)?(www\.)?/, '');
    return { domain: cleanDomain, isCustomDomain: true };
  }

  return { domain: "", isCustomDomain: false };

}
export function removeDash(value: string) {
  // Replace all dashes with an empty string
  return value.replace(/-/g, " ");
}


