import {
  createContext,
  useReducer,
  useContext,
  useMemo,
  useLayoutEffect,
} from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';

import { getStorageItem } from 'utils/local-storage';
import { getCustomUrl } from 'utils/validation';
import { Loader } from 'components';
import useScript from 'utils/libs/useScript';

import { customerInitialState, initialState } from './state';
import { ContextType, ProviderProps } from './types/type';
import { rootReducer } from './reducers';
import { customerReducer } from './reducers/customerReducer';
import { Action } from './actions';


export const Context = createContext<ContextType | null>(null);

const Provider = ({ children }: ProviderProps) => {
  const PAYMENT_URL = process.env.REACT_APP_REMITA_INLINE;
  useScript(`${PAYMENT_URL}`);

  const [state, dispatch] = useReducer(rootReducer, initialState);
  const [customerState, customerDispatch] = useReducer(
    customerReducer,
    customerInitialState
  );
  const {domain, isCustomDomain} = getCustomUrl()


  const getVendorStoreInfo = async () => {
    if ( domain) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/vendor/v1/vendors?${isCustomDomain?'domainName':'storeId'}=${domain}`
      );
      return data;
    }
  };


  const { data, isLoading } = useQuery('store-info', getVendorStoreInfo, {
    keepPreviousData: true,
  });
  
  const getVendorContactInfo = async (storeName: string | undefined) => {
    if (!storeName) return;
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/vendor/v1/vendors/store/contact-details/${storeName}`
    );
    return data;
  };
  
  const { data: vendorContact } = useQuery(
    ['contact-info', data?.storeId], 
    () => getVendorContactInfo(data?.storeId),
    {
      keepPreviousData: true,
      enabled: !!data?.storeId, 
    }
  );
  const storeTemplate = data?.storeTemplate ?? null;

  const user = useMemo(() => {
    return getStorageItem('isAuth');
  }, []);

  const cartItems = useMemo(() => {
    return getStorageItem('cartItems');
  }, []);

  useLayoutEffect(() => {
    dispatch({
      type: Action.LOGIN,
      payload: user,
    });
    dispatch({
      type: Action.VENDOR_BUSINESS,
      payload: { ...data, ...vendorContact?.[0] },
    });

    dispatch({
      type: Action.VENDOR_STORE_DETAILS,
      payload: {
        storeSettings: storeTemplate ? { ...storeTemplate } : null,
      },
    });

    dispatch({
      type: Action.CART_ITEMS,
      payload: cartItems || [],
    });
  }, [user, data, vendorContact, cartItems]);

  if (isLoading) {
    return <Loader withBg/>;
  }

  return (
    <Context.Provider
      value={{ state, dispatch, customerState, customerDispatch }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;

export const useAppContext = () => {
  return useContext(Context) as ContextType;
};
