import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAppContext } from 'context';
import { Text } from 'components';
import { LogoButton } from 'features/store-front/components/store-navbar/styles/store-navbar.styles';
import { getCustomUrl} from 'utils/validation';

const VendorLogo = () => {
  const [error, setError] = useState(false);
  const {
    state: { vendorBusiness, vendorStoreDetails },
  } = useAppContext();
  const {domain} = getCustomUrl();
  const customTheme = vendorStoreDetails?.storeSettings ?? null;

  return vendorBusiness?.businessLogo?.filename ||
    customTheme?.logo?.filename ? (
    !error ? (
      <LogoButton>
        <LazyLoadImage
          src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${
            customTheme
              ? customTheme?.logo?.filename
              : vendorBusiness?.businessLogo?.filename
          } `}
          alt="logo"
          effect="blur"
          width="100%"
          onError={() => {
            setError(true);
          }}
        />
      </LogoButton>
    ) : (
      <Text as="h2">{domain}</Text>
    )
  ) : (
    <Text as="h2">{domain}</Text>
  );
};

export default VendorLogo;
