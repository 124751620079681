import React from "react";
import { IconProps } from "../type";


const UserIcon=({width,height, color}:IconProps)=>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width :"45"}
      height={height? height:"45"}
      fill="none"
      viewBox="0 0 45 45"
    >
      <path
        fill={color || "#F4B7B5"}
        d="M22.5 0C10.303 0 0 10.303 0 22.5S10.303 45 22.5 45 45 34.697 45 22.5 34.697 0 22.5 0zm0 11.25c3.886 0 6.75 2.862 6.75 6.75s-2.864 6.75-6.75 6.75c-3.884 0-6.75-2.862-6.75-6.75s2.866-6.75 6.75-6.75zM11.011 33.237c2.019-2.97 5.385-4.95 9.239-4.95h4.5c3.857 0 7.22 1.98 9.239 4.95-2.876 3.078-6.955 5.013-11.489 5.013-4.534 0-8.613-1.935-11.489-5.013z"
      ></path>
    </svg>
  );
}

export default UserIcon;